import { render, staticRenderFns } from "./InventoryList.vue?vue&type=template&id=4caa66ca&scoped=true"
import script from "./InventoryList.vue?vue&type=script&lang=js"
export * from "./InventoryList.vue?vue&type=script&lang=js"
import style0 from "./InventoryList.vue?vue&type=style&index=0&id=4caa66ca&prod&lang=scss&scoped=true"
import style1 from "./InventoryList.vue?vue&type=style&index=1&id=4caa66ca&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4caa66ca",
  null
  
)

export default component.exports